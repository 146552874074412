export const gray600 = '#6B6B6B';

export const blue500 = '#2374BB';
export const primaryBlue = blue500;

export const green700 = '#2e7d32';

export const gray800 = '#333333';

export const orange700 = '#ff961d';
